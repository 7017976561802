import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "mobile12-5"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/others/ultramobile.png"}) { ...eyecatchImg },
    compare_mobile: file(relativePath: { eq: "mobile/compare_mobile.png"}) { ...normalImg },
    campus: file(relativePath: { eq: "mobile/campus.png"}) { ...normalImg },
    pc_with_bag: file(relativePath: { eq: "mobile/pc_with_bag.png"}) { ...normalImg },
    size: file(relativePath: { eq: "mobile/size.png"}) { ...normalImg },
    wiredlan: file(relativePath: { eq: "mobile/wiredlan.png"}) { ...normalImg },
    typec: file(relativePath: { eq: "mobile/typec.png"}) { ...normalImg },
    notepad: file(relativePath: { eq: "mobile/notepad.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`12.5インチノートパソコンのサイズ感とおすすめの機種`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="12.5インチノートパソコン" mdxType="Image" />
    <p>{`モバイルノートは13.3インチがスタンダードなサイズであるが、狭い机やカフェ、新幹線などで使うとやや圧迫感があり、パソコンの取り回しにストレスを感じることもある。そんな時に絶妙なサイズ感が12.5インチである。`}</p>
    <p>{`本ページでは、そんな12.5インチの大きさについて具体的なイメージが分かるよう解説し、ノートパソコンのおすすめの機種についても述べていく。出張の多いビジネスマンや、カフェや講義スペースなどの狭い場所でパソコンを使うことが多い大学生にとっては有力な選択肢となるはずである。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "125インチの大きさは？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#125%E3%82%A4%E3%83%B3%E3%83%81%E3%81%AE%E5%A4%A7%E3%81%8D%E3%81%95%E3%81%AF%EF%BC%9F",
        "aria-label": "125インチの大きさは？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.5インチの大きさは？`}</h2>
    <Image {...props} name="compare_mobile" alt="12.5インチノートパソコンの大きさ" mdxType="Image" />
    <p>{`12.5インチのパソコンと、13.3インチのパソコンを比べると次のようになった。
13.3インチの方が一回り大きいことが分かるが、画面の大きさを見るとそこまで大きく変わらないという意見もあるかもしれない。`}</p>
    <p>{`実際に普段13.3インチを使っている私も画面を見て小さいとは思わないレベルである。`}</p>
    <h3 {...{
      "id": "B5キャンパスノートとの大きさの比較",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#B5%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%91%E3%82%B9%E3%83%8E%E3%83%BC%E3%83%88%E3%81%A8%E3%81%AE%E5%A4%A7%E3%81%8D%E3%81%95%E3%81%AE%E6%AF%94%E8%BC%83",
        "aria-label": "B5キャンパスノートとの大きさの比較 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B5キャンパスノートとの大きさの比較`}</h3>
    <Image {...props} name="campus" caption="12.5インチノートとキャンパスノートの大きさを比較" mdxType="Image" />
    <p>{`12.5インチノートパソコンはB5キャンパスノートと比べると横に3cm、縦に1.5cmほど大きいもののそれほど変わらない。`}</p>
    <h3 {...{
      "id": "鞄への収まりも良く、携帯性に優れる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9E%84%E3%81%B8%E3%81%AE%E5%8F%8E%E3%81%BE%E3%82%8A%E3%82%82%E8%89%AF%E3%81%8F%E3%80%81%E6%90%BA%E5%B8%AF%E6%80%A7%E3%81%AB%E5%84%AA%E3%82%8C%E3%82%8B",
        "aria-label": "鞄への収まりも良く、携帯性に優れる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`鞄への収まりも良く、携帯性に優れる`}</h3>
    <Image {...props} name="pc_with_bag" caption="会社用ショルダーバッグにもすっぽり入る" mdxType="Image" />
    <p>{`B5程度の書類が楽に入るように作られている鞄は多いので、12.5インチノートは多くの鞄で収まりがよくスッポリと入る。これはビジネスマンや大学生にとってかなりありがたい。`}</p>
    <h3 {...{
      "id": "飛行機、新幹線、カフェなど狭い場所での取り回しが良い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%A3%9B%E8%A1%8C%E6%A9%9F%E3%80%81%E6%96%B0%E5%B9%B9%E7%B7%9A%E3%80%81%E3%82%AB%E3%83%95%E3%82%A7%E3%81%AA%E3%81%A9%E7%8B%AD%E3%81%84%E5%A0%B4%E6%89%80%E3%81%A7%E3%81%AE%E5%8F%96%E3%82%8A%E5%9B%9E%E3%81%97%E3%81%8C%E8%89%AF%E3%81%84",
        "aria-label": "飛行機、新幹線、カフェなど狭い場所での取り回しが良い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`飛行機、新幹線、カフェなど狭い場所での取り回しが良い`}</h3>
    <Image {...props} name="size" caption="僅かなコンパクトさが快適性を大きく向上させる" mdxType="Image" />
    <p>{`奥行き、幅ともに狭い場所では、少しでもノートパソコンのサイズは小さくしておきたいものである。飛行機や新幹線、カフェなどの狭いスペースで威力を発揮するのが13.3インチと比べて僅かに短い奥行きと幅の差である。`}</p>
    <h3 {...{
      "id": "総評：出張の多いビジネスマンやカフェでの作業が多い人におすすめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%B7%8F%E8%A9%95%EF%BC%9A%E5%87%BA%E5%BC%B5%E3%81%AE%E5%A4%9A%E3%81%84%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%9E%E3%83%B3%E3%82%84%E3%82%AB%E3%83%95%E3%82%A7%E3%81%A7%E3%81%AE%E4%BD%9C%E6%A5%AD%E3%81%8C%E5%A4%9A%E3%81%84%E4%BA%BA%E3%81%AB%E3%81%8A%E3%81%99%E3%81%99%E3%82%81",
        "aria-label": "総評：出張の多いビジネスマンやカフェでの作業が多い人におすすめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`総評：出張の多いビジネスマンやカフェでの作業が多い人におすすめ`}</h3>
    <p>{`12.5インチはもちろん取り回しが非常に優れる反面、作業スペースが狭い、あるいはスペースを広くするために文字サイズを小さくせざるを得ないというデメリットがある。`}</p>
    <p>{`このため、`}<strong parentName="p"><em parentName="strong">{`主に家で使うなど持ち運びが少ない人には全く適していない`}</em></strong>{`だろう。また、比較的広いスペースが必要となりがちな動画編集などの`}<strong parentName="p"><em parentName="strong">{`クリエイティブな作業にも窮屈さを感じる`}</em></strong>{`。`}</p>
    <p>{`よって対象となる`}<strong parentName="p"><em parentName="strong">{`ユーザーとしては外出先で使い持ち運びの頻度が高い人に限定`}</em></strong>{`される。そのほかの人は14インチなどもう一回り大きいサイズを購入した方が幸せになれるだろう。`}</p>
    <h2 {...{
      "id": "125インチノートパソコンのおすすめはVAIO-SX12",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#125%E3%82%A4%E3%83%B3%E3%83%81%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AFVAIO-SX12",
        "aria-label": "125インチノートパソコンのおすすめはVAIO SX12 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12.5インチノートパソコンのおすすめはVAIO SX12`}</h2>
    <p>{`モバイルPCでの標準的なサイズは13.3インチになるので、12.5インチ付近の機種はそれほど多く発売されていない。`}</p>
    <p>
そんな中、<a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=41968&murl=https%3A%2F%2Fstore.vaio.com%2Fshop%2Fpages%2Fsx126g.aspx&LSNSUBSITE=LSNSUBSITE" target="_blank" rel="nofollow noopener">VAIO SX12</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=596216.1&type=10" />は完成度が非常に高く、<strong><b>持ち歩きの多いビジネスパーソンや大学生に取って最適な選択肢</b></strong>となり得るだろう。
    </p>
    <p>{`このVAIO SX12に特有の優れた特徴について説明していきたい。`}</p>
    <h3 {...{
      "id": "キーピッチがデスクトップと同等の19mm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AD%E3%83%BC%E3%83%94%E3%83%83%E3%83%81%E3%81%8C%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%A8%E5%90%8C%E7%AD%89%E3%81%AE19mm",
        "aria-label": "キーピッチがデスクトップと同等の19mm permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`キーピッチがデスクトップと同等の19mm`}</h3>
    <p>{`キーとキーの間の間隔をキーピッチと呼び、キーピッチが短くなると窮屈な姿勢でのタイピングを強いられることになり、肩が凝りやすくなるなど疲れを引き起こしやすい。`}</p>
    <p>{`ところが`}<strong parentName="p">{`VAIO SX12はキーピッチが19mmと`}<em parentName="strong">{`フルピッチキーボード`}</em>{`となっている`}</strong>{`。12.5インチパソコンの多くは18.5mmなど微妙に間隔を狭めてきている中、狭淵ベゼルやキー配置により広いキーピッチを確保していることは大きなポイントである。`}</p>
    <h3 {...{
      "id": "有線LANサポート、端子の豊富さ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9C%89%E7%B7%9ALAN%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%80%81%E7%AB%AF%E5%AD%90%E3%81%AE%E8%B1%8A%E5%AF%8C%E3%81%95",
        "aria-label": "有線LANサポート、端子の豊富さ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`有線LANサポート、端子の豊富さ`}</h3>
    <Image {...props} name="wiredlan" caption="有線LANで非常時も安定" mdxType="Image" />
    <p><strong parentName="p">{`SX12はモバイルノートでは省略しがちな`}<em parentName="strong">{`有線LANをサポート`}</em>{`している点は大きい`}</strong>{`。重要なミーティング中やオンライン授業などで無線が切れて、音信不通になるのが不安な人は有線接続によりリスクを最大限減らすことができる。`}</p>
    <p>{`その他、Thunderboltに対応したType C端子、USB端子、HDMI端子など豊富な端子を十分に揃えているため、`}<strong parentName="p"><em parentName="strong">{`基本的に外付けのドッキングステーションや変換器を持ち歩く必要はない`}</em></strong>{`だろう。`}</p>
    <h3 {...{
      "id": "USB-Type-C-映像出力対応",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-Type-C-%E6%98%A0%E5%83%8F%E5%87%BA%E5%8A%9B%E5%AF%BE%E5%BF%9C",
        "aria-label": "USB Type C 映像出力対応 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-C 映像出力対応`}</h3>
    <Image {...props} name="typec" caption="23.8インチモニターと接続した様子" mdxType="Image" />
    <p>{`VAIO SX12にはUSB Type-Cの機能として`}<em parentName="p">{`Power Delivery`}</em>{`(急速充電)と`}<em parentName="p">{`Display Portオルタネートモード`}</em>{`という映像出力機能が備わっている。`}</p>
    <p>{`すなわち`}<strong parentName="p"><em parentName="strong">{`映像出力と充電を一つのケーブルで実現できる`}</em></strong>{`ということである。十分な電力量がモニターの電源経由で提供されるので、付属のコンセントを挿す必要が無い。`}</p>
    <p><a parentName="p" {...{
        "href": "https://amzn.to/2XQa4TG",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Type Cモニター`}</a>{`と`}<a parentName="p" {...{
        "href": "https://amzn.to/3oJINxI",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Thunderboltケーブル`}</a>{`があればこれを実現可能である。`}</p>
    <p>{`またモニター同士に`}<a parentName="p" {...{
        "href": "https://amzn.to/3Dqsudu",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`DisplayPortケーブル`}</a>{`と接続する(`}<em parentName="p">{`デイジーチェーン接続`}</em>{`と呼ばれる)ことで、画像のように`}<em parentName="p">{`トリプルディスプレイ`}</em>{`も容易に実現できる。`}</p>
    <h3 {...{
      "id": "アシンメントリーなノートパッド配置",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%A2%E3%82%B7%E3%83%B3%E3%83%A1%E3%83%B3%E3%83%88%E3%83%AA%E3%83%BC%E3%81%AA%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%83%83%E3%83%89%E9%85%8D%E7%BD%AE",
        "aria-label": "アシンメントリーなノートパッド配置 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`アシンメントリーなノートパッド配置`}</h3>
    <Image {...props} name="notepad" caption="ノートパッドの右側の方が長い" mdxType="Image" />
    <p>{`タイピングを行う時はキーポジション上、右の手の方が左より可動域が大きい。このためノートパッドに親指の付け根が当たり誤動作を起こすことも多い。`}</p>
    <p>{`これを絶妙に回避しているのがこのモデルである。`}<strong parentName="p"><em parentName="strong">{`誤動作で勝手にカーソルが動く可能性が低いためタイピングが非常に安定する`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "長時間バッテリー、軽量性、堅牢性",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%95%B7%E6%99%82%E9%96%93%E3%83%90%E3%83%83%E3%83%86%E3%83%AA%E3%83%BC%E3%80%81%E8%BB%BD%E9%87%8F%E6%80%A7%E3%80%81%E5%A0%85%E7%89%A2%E6%80%A7",
        "aria-label": "長時間バッテリー、軽量性、堅牢性 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`長時間バッテリー、軽量性、堅牢性`}</h3>
    <p>{`その他の観点も抜かりがない、`}<em parentName="p">{`最大27時間を超える長時間バッテリー`}</em>{`に、`}<em parentName="p">{`900gを切る軽量性`}</em>{`で丸一日持ち歩いても問題が無い。`}</p>
    <p>{`堅牢性の観点では`}<em parentName="p">{`水漏れに特に強く`}</em>{`、万が一飲み物を大胆にこぼしてしまってもデータを守ることができる他、落下試験など大手日本メーカーの厳しい品質要求に答えた魅力的な品質に仕上がっている。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=41968&murl=https%3A%2F%2Fstore.vaio.com%2Fshop%2Fgoods%2Fcto.aspx%3Fgoods%3DV0008701&LSNSUBSITE=LSNSUBSITE" target="_blank" rel="nofollow noopener">VAIO SX12</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=596216.1&type=10" />
    </SmallBox>
    <h2 {...{
      "id": "その他おすすめの125インチノートパソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE125%E3%82%A4%E3%83%B3%E3%83%81%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "その他おすすめの125インチノートパソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他おすすめの12.5インチノートパソコン`}</h2>
    <p>{`その他12.5インチ付近のノートパソコンで人気モデルを紹介する。価格がリーゾナブルなため十分検討に値するだろう。`}</p>
    <h3 {...{
      "id": "Suraface-Laptop-Go-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Suraface-Laptop-Go-3",
        "aria-label": "Suraface Laptop Go 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Suraface Laptop Go 3`}</h3>
    <p>{`デザイン性が高いマイクロソフトの12.4インチノートパソコン。Laptop GoのGoは恐らく外出先に持っていくという意味をこめてGoと名付けられたと思われる。ポケモンGoと同じ発想である。`}</p>
    <p>{`さらに価格がVAIOと比べるとリーゾナブルで、公式サイトからは`}<strong parentName="p"><em parentName="strong">{`小中高及び大学、専門学校に在籍する全ての学生とその両親、教育機関関係者がご利用いただけるという強力な学割`}</em></strong>{`も存在する。`}</p>
    <p>{`端子数は多くなく、重量が1.1kg越えで12.4インチとしてはやや重い。しかし質感に優れバッテリーも１５時間程度と一日中使うことができる上、パソコンの取り回しに優れるというメリットは十分に享受できるだろう。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=39726&murl=https%3A%2F%2Fwww.microsoft.com%2Fja-jp%2Fd%2Fsurface-laptop-go-3%2F8p0wwgj6c6l2" target="_blank" rel="nofollow noopenner">Suraface Laptop Go 3</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=1164840.1&type=10" />
    </SmallBox>
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`12.5インチのノートパソコンの大きさや、VAIO SX12をはじめ、人気が高いモデルについて紹介した。`}</p>
    <p>{`生産効率は落としたくないが、外に持ち歩くことも多く、13.3インチと比較してより小回りが効いたパソコンが欲しいという人にとっては良い選択肢となるのではないだろうか。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      